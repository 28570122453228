<template>
  <LayoutPage title="Reportes de cobranzas">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 mx-5">
            <v-col cols="6" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaInicio)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  locale="es-ar"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaFin)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaInicio"
                  :max="
                    moment()
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')
                  "
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <v-data-table
      :headers="headers"
      :items="cobranzas.filter((row) => row.Fecha !== fechaActual)"
      :loading="loading"
      no-data-text="Sin registros para mostrar"
      loading-text="Cargando... Espere por favor"
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="rowsPerPage"
    >
      <template v-slot:[`item.Nombre`]="{ item }">
        {{ getNombreArchivo(item.Nombre) + ".csv" }}
      </template>
      <template v-slot:[`item.Fecha`]="{ item }">
        {{ formatDate(item.Fecha) }}
      </template>
      <template v-slot:[`item.Subtotal`]="{ item }">
        {{ formatImport(item.Subtotal) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- descargar excel -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="green darken-3"
              @click="downloadExcel(item)"
              v-on="on"
              ><v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar Excel</span>
        </v-tooltip>
        <v-tooltip bottom> </v-tooltip>
        <!-- descargar pdf -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red darken-3"
              @click="generatePDF(item)"
              v-on="on"
              ><v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar PDF</span>
        </v-tooltip>
        <!-- vista previa -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="grey darken-3"
              @click="showPreview(item)"
              v-on="on"
              ><v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Vista previa</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-row class="no-gutters mt-2 mx-2">
          <v-flex xs12>
            <span>Mostrar</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ rowsPerPage }}
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in rowsPerPageArray"
                  :key="index"
                  @click="rowsPerPage = number"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-show="pageCount > 0"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    <!-- ver el pdf mas grande -->
    <div id="pdf-preview"></div>

    <!-- PREVIEW REPORTE -->
    <v-dialog
      v-model="preview"
      max-width="80%"
      class="white"
      style="z-index: 14000 !important;"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-bold ">
          REPORTE DE COBRANZAS
          <v-spacer />
          <span class="body-1  grey--text text--darken-3">
            Fecha: {{ formatDate(previewData.fecha) }}
          </span>
        </v-card-title>
        <v-divider class="ma-0"></v-divider>

        <v-data-table
          :headers="headerPreview"
          :items="previewData.items"
          class="ma-2"
          hide-default-footer
          disable-pagination
          disable-sort
          no-data-text="Sin registros para mostrar"
        >
          <template v-slot:[`item.fecha_pago`]="{ item }">
            {{ formatDate(item.fecha_pago.slice(0, 10)) }}
          </template>
          <template v-slot:[`item.total_pago`]="{ item }">
            {{ formatImport(item.total_pago) }}
          </template>
          <!-- ------ comision----- -->
          <template v-slot:[`item.comision`]="{ item }">
            {{ item.comision ? formatImport(item.comision) : "-" }}
          </template>
          <!-- ------ iva----- -->
          <template v-slot:[`item.iva`]="{ item }">
            {{ item.iva ? formatImport(item.iva) : "-" }}
          </template>
          <!-- ------ retencion----- -->
          <template v-slot:[`item.retencion`]="{ item }">
            {{ item.retencion ? formatImport(item.retencion) : "-" }}
          </template>
        </v-data-table>
        <v-divider class="ma-0"></v-divider>

        <!-- --------TOTAL COBRADO---------- -->
        <v-row justify="end" align="center" no-gutters class="pa-3">
          <v-chip
            :ripple="false"
            color="green lighten-1"
            class="white--text body-1"
          >
            Total:
            <span class="font-weight-bold">
              {{ formatImport(previewData.total) }}
            </span>
          </v-chip>
        </v-row>
        <!-- -----------TOTAL COMISION ------------ -->
        <v-row justify="end" align="center" no-gutters class="px-3 pt-3 pb-1">
          <v-chip :ripple="false" color="grey lighten-2 mr-4">
            Total comisiones:
            <span class="font-weight-bold ml-2">
              {{ formatImport(previewData.totalComision) }}
            </span>
          </v-chip>
          <!-- TOTAL IVA -->
          <v-chip :ripple="false" color="grey lighten-2">
            Total iva:
            <span class="font-weight-bold ml-2">
              {{ formatImport(previewData.totalIva) }}
            </span>
          </v-chip>
        </v-row>

        <!-- ----TOTAL RETENCION------- -->
        <v-row justify="end" align="center" no-gutters class="px-3 pt-1 pb-3">
          <v-chip
            v-if="CLIENTE_CUENTA_DATA.sujeto_retencion"
            :ripple="false"
            color="grey lighten-2"
          >
            Total retencion:
            <span class="font-weight-bold ml-2">
              {{ formatImport(previewData.totalRetencion) }}
            </span>
          </v-chip>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Modal de Fechas -->
    <v-dialog v-model="RestriccionFecha" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          <v-col>
            <v-row
              ><div class="flex justify-center">
                <v-icon x-large color="red">mdi-close-octagon</v-icon>
              </div></v-row
            >
            <v-row></v-row>
            <v-row>
              <div class="flex justify-center">
                <h1>Rango de Fechas</h1>
              </div>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text>No puede ser mayor a 31 dias</v-card-text>
        <v-card-actions>
          <div class="flex justify-center">
            <v-btn
              color="green darken-1"
              class="text-white"
              small
              @click="closeModal"
            >
              Aceptar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LayoutPage>
</template>

<script>
import moment from "moment";
import LayoutPage from "../../../components/LayoutPage.vue";
import formatDate from "../../../utils/formatDate";
import formatImport from "../../../utils/formatImport";
import reportesService from "../../../services/reportes";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import PDFObject from "pdfobject";

import { dataEncabezadoPDF } from "@/utils/dataEncabezadoPDF";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "ReporteCobranzas",
  components: { LayoutPage },
  data() {
    return {
      RestriccionFecha: false,
      panel: 0,
      fechaInicio: moment()
        .subtract(1, "weeks")
        .format("YYYY-MM-DD"),

      fechaFin: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),

      menu1: false,
      menu2: false,
      headers: [
        { text: "FECHA", value: "Fecha", sortable: false },
        { text: "NOMBRE", value: "Nombre", sortable: false },
        {
          text: "REGISTROS",
          value: "Registros",
          sortable: false,
          align: "center",
        },
        { text: "IMPORTE COBRADO", value: "Subtotal", sortable: false },
        {
          text: "ACCIONES",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      headerPreview: [
        { text: "Cuenta", value: "cuenta" },
        { text: "Referencia", value: "referencia" },
        { text: "Fecha cobro", value: "fecha_pago" },
        { text: "Medio de pago", value: "medio_pago" },
        { text: "Tipo", value: "canal_pago" },
        { text: "Estado", value: "pagoestado" },
        { text: "Monto", value: "total_pago" },
        { text: "Comision", value: "comision" },
        { text: "IVA", value: "iva" },
      ],

      cobranzas: [],
      loading: false,

      rowsPerPageArray: [7, 14, 30],
      rowsPerPage: 7, // cantidad de registros que se muestran en la tabla
      page: 1, // pagina actual de la tabla

      pageCount: 0,

      dataExcelExport: [],

      fechaActual: moment().format("YYYY-MM-DD"),
      preview: false,
      previewData: [],

      formatDate,
      formatImport,
      moment,
    };
  },

  // si el usuario cambia de cliente, volver a realizar la llamada a la api
  watch: {
    getCurrentClientId() {
      this.getData();
    },
    getCurrentAccountId() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters([
      "getCurrentClientId",
      "getCurrentAccountId",
      "CLIENTE_CUENTA_DATA",
    ]),
    daysDifference() {
      return moment(this.fechaFin).diff(moment(this.fechaInicio), "days");
    },
  },

  mounted() {
    this.$nextTick(() => this.getData());
  },

  methods: {

    closeModal() {
      this.RestriccionFecha = false;
    },

    updatePageCount() {
      const daysDifference = moment(this.fechaFin).diff(
        moment(this.fechaInicio),
        "days"
      );
      this.pageCount = Math.ceil(daysDifference / this.rowsPerPage);
    },
    async getData() {
      if (this.daysDifference > 31) {
        this.RestriccionFecha = true;
        return;
      }
      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;

        const params = {
          FechaInicio: moment(this.fechaInicio).format("DD-MM-YYYY"),
          FechaFin: moment(this.fechaFin).format("DD-MM-YYYY"),
          ClienteId: this.getCurrentClientId,
          CuentaId: this.getCurrentAccountId,
        };

        const daysDifference = moment(this.fechaFin).diff(
          moment(this.fechaInicio),
          "days"
        );

        //si la diferencia de fechas es mayor a 31 dias, no hacer el llamado, mostrar una alerta de error
        if (daysDifference > 31) {
          this.$toastr.e("El rango de fechas no puede ser mayor a 31 días");
          this.loading = false;
          return;
        }

        const data = await reportesService.reporteCobranza(params);

        this.cobranzas = data?.data?.Cobranzas || [];
        this.loading = false;

        this.cobranzas.length > 0
          ? this.updatePageCount()
          : (this.pageCount = 0);
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },

    async downloadExcel(item) {
      const HEADER = ["\ufeffREPORTE COBRANZAS", "\nFECHA", item.Fecha].join(
        ";"
      );

      let HEADER_TABLE = [
        "\nCUENTA",
        "REFERENCIA",
        "FECHA COBRO",
        "MEDIO DE PAGO",
        "TIPO",
        "ESTADO",
        "MONTO $",
        "COMISIÓN $",
        "IVA $",
      ].join(";");

      HEADER_TABLE =
        HEADER_TABLE +
        (this.CLIENTE_CUENTA_DATA.sujeto_retencion ? ";RETENCION $" : "");

      const ROWS = item.Pagos.map(
        ({
          cuenta,
          referencia,
          fecha_pago,
          medio_pago,
          canal_pago,
          pagoestado,
          total_pago,
          comision,
          iva,
          retencion,
        }) => {
          let row = [
            "\n" + cuenta,
            referencia,
            moment(fecha_pago.slice(0, 10)).format("l"),
            medio_pago,
            canal_pago,
            pagoestado,
            formatImport(total_pago, true),
            formatImport(comision, true),
            formatImport(iva, true),
          ];
          if (this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
            row.push(retencion ? formatImport(retencion, true) : "-");
          }
          return row.join(";");
        }
      ).join("");

      const FOOTER = [
        ["\n", "", "", "", "", "CANT OPERACIONES", item.Registros].join(";"),
        /* total cobrado */
        [
          "\n",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL COBRADO $",
          formatImport(item.Subtotal, true),
          "",
        ].join(";"),
        /* total comision */
        [
          "\n",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL COMISIONES $",
          formatImport(item.TotalComision, true),
          "",
        ].join(";"),
        /* total iva */
        [
          "\n",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL IVA $",
          formatImport(item.TotalIva, true),
          "",
        ].join(";"),
        /* total retencion */
        item.TotalRetencion
          ? [
              "\n",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "TOTAL RETENCION $",
              formatImport(item.TotalRetencion, true),
              "",
            ].join(";")
          : "",
      ];

      const DATA = [HEADER, HEADER_TABLE, ROWS, FOOTER].join(";");

      const csvFile = new Blob([DATA.replaceAll(";,", "")], {
        encoding: "utf-8",
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(csvFile, this.getNombreArchivo(item.Nombre));
    },

    showPreview(item) {
      this.previewData = {
        nombre: item.Nombre,
        fecha: item.Fecha,
        total: item.Subtotal,
        items: item.Pagos,
        totalComision: item.TotalComision,
        totalIva: item.TotalIva,
        totalRetencion: item.TotalRetencion,
      };

      //COLUMNA RETENCION SOLO SI EL CLIENTE ES SUJETO DE RETENCION
      //primero ver si ya existe la columna
      const columnaRetencion = this.headerPreview.find(
        (h) => h.value === "retencion"
      );
      //si no tengo la columna, la agrego al header
      if (!columnaRetencion) {
        this.headerPreview = [
          ...this.headerPreview,
          { text: "Retencion", value: "retencion" },
        ];
      }

      //ocultar columna retencion en DETALLE si no es sujeto de retencion
      if (!this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
        this.headerPreview = this.headerPreview.filter(
          (h) => h.value !== "retencion"
        );
      }

      this.preview = true;
    },

    generatePDF(item) {
      const contenido = [
        dataEncabezadoPDF(this.CLIENTE_CUENTA_DATA, item.NroReporte),
        {
          text: "REPORTE DE COBRANZAS",
          style: "subheader",
          margin: [0, 20, 0, 10],
        },
        {
          text: `Fecha: ${formatDate(item.Fecha)}`,
          style: "subheader",
          margin: [0, 0, 0, 10],
          alignment: "right",
          bold: false,
        },
        {
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: [
              (() => {
                let headers = [];
                for (let i = 0; i < this.headerPreview.length; i++) {
                  headers.push({
                    text: this.headerPreview[i].text,
                  });
                }
                //no las de value retencion
                headers.filter((h) => h.text !== "Retencion");
                return headers;
              })(),
              ...item.Pagos.map((pago) => [
                pago.cuenta,
                pago.referencia,
                formatDate(pago.fecha_pago.slice(0, 10)),
                pago.medio_pago,
                pago.canal_pago,
                pago.pagoestado,
                formatImport(pago.total_pago),
                pago.comision ? formatImport(pago.comision) : "-",
                pago.iva ? formatImport(pago.iva) : "-",
              ]),
            ],
          },
          layout: "headerLineOnly",
          style: "table",
        },
        /* total cobrado */
        {
          text: `Total Cobrado: ${formatImport(item.Subtotal)}`,
          style: "subheader",
          margin: [0, 20, 0, 0],
          alignment: "right",
        },
        /* total comisiones */
        {
          text: `Total Comisiones: ${formatImport(item.TotalComision)}`,
          style: "subheader",
          margin: [0, 20, 0, 0],
          alignment: "right",
        },
        /* total iva */
        {
          text: `Total IVA: ${formatImport(item.TotalIva)}`,
          style: "subheader",
          margin: [0, 20, 0, 0],
          alignment: "right",
        },
      ];

      const styles = {
        header: {
          fontSize: 10,
          alignment: "left",
          margin: [0, 5, 0, 0],
        },
        subheader: {
          fontSize: 11,
          bold: true,
          alignment: "left",
          margin: [0, 5, 0, 10],
        },
        table: {
          fontSize: 9,
          bold: false,
        },
      };

      const images = {
        wee: `${window.location.origin}/assets/img/wee_logo.png`,
      };

      const docDefinition = {
        content: contenido,
        styles: styles,
        images: images,
      };

      //PREVIEW
      /* var pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        var pdfUrl = URL.createObjectURL(blob);
        PDFObject.embed(pdfUrl, "#pdf-preview");
      }); */

      // DESCARGAR
      pdfMake
        .createPdf(docDefinition)
        .download(this.getNombreArchivo(item.Nombre));
    },

    getNombreArchivo(nombre) {
      return `${nombre.replaceAll(" ", "-").replaceAll(".", "-")}-COBRANZAS`;
    },
  },
};
</script>

<style scoded>
.v-tooltip__content {
  width: 120px;
}
</style>
